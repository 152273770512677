/* Scrollbar*/
::-webkit-scrollbar { width: 6px;  }/* for vertical scrollbars */
::-webkit-scrollbar-track { background: rgba(0, 0, 0, 0.0); }
::-webkit-scrollbar-thumb { background: rgb(185, 185, 185) }

#homeIndex h1, #homeIndex h2, #homeIndex h3, #homeIndex h4, #homeIndex h5, #homeIndex h6 {
  color: var(--colorText);
  font-family: 'Font-Regular';
  font-weight: bold;
}
#menu {
  display: flex;
  position: fixed;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: rgb(0, 0, 0, 0.9);
  height: 100vh;
  width: 100%;
  z-index: 99;
}
#menu li {
  margin-bottom: 12px;
}
#menu li a,  #menu li span{
  font-size: 28px;
  color: white;
  /*font-family: "Font-Bold";*/
}
.btnMenu {
  padding: 8px 12px !important;
  z-index: 990;
  position: inherit;
  border-radius: 8px !important;
}
.btnMenu i{
  margin-top: 5px;
  font-size: 1.5em;
}
#homeIndex {
  background-image: url('../../assets/images/background.jpg');
  /*background: var(--colorDefault);*/
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 100vh;
}

#homeIndex .infoTop {
  display: block;
  background: rgb(21, 21, 21);
  font-family: "Font-Regular" !important;
  padding: 5px 15px !important;
  font-size: 14px !important;
  color: var(--colorDefault) !important;
  margin-top: -12px;
}

#homeIndex #navbar.onScroll .infoTop {
  margin-top: -5px;
}

#homeIndex #navbar{
  padding: 12px 0;
  /*background-color: var(--backgroundDefault);*/
}

#homeIndex #navbar.onScroll{
  padding: 5px 0;
  position: fixed;
  width: 100%;
  /*background-color: var(--backgroundDefault);*/
  z-index: 99;
  box-shadow: 0px 0px 5px -1px var(--shadow);
  --webkit-box-shadow: 0px 0px 5px -1px var(--shadow);
}

#homeIndex .logo img{
  max-width: 120px;
}

#homeIndex .logo h1{
  font-size: 45px;
  font-weight: bold;
  color: var(--colorDefault);
  padding: 15px 0;
  margin-bottom: 0;
  cursor: pointer;
}

/*#homeIndex a {
  color: var(--colorDefault);
  text-transform: uppercase;
  line-height: normal;
  font-weight: bold;
}*/

#homeIndex .bodyIndex {
  padding: 60px 0;
  height: 100%;
  place-content: stretch;
  /*background: var(--backgroundDefault);
  border-top-right-radius: 50px;
  border-top-left-radius: 50px;*/
}

#homeIndex .bodyIndex h1 {
  max-width: 500px;
  font-size: 55px;
  color: #fff;
}

#homeIndex #navbar a,  #homeIndex #navbar span{
  font-family: 'Font-Bold';
  text-transform: none;
  font-size: 20px;
  padding: 12px 15px;
  color: #fff;
}

#homeIndex #navbar a:hover {
  color: var(--colorDefault);
}

#homeIndex #navbar a.btn-default{
  color: #fff;
}

#homeIndex .bodyIndex p {
  margin-top: 15px;
  font-size: 16px;
  color: rgb(185, 185, 185);
}

#homeIndex .bodyIndex .card p {
  color: #004db9;
  margin-bottom: 0;
  padding: 15px;
  font-weight: 600;
  font-size: 18px;
}

#homeIndex .bodyIndex button {
  padding: 12px 35px;
}
#homeIndex p {
  font-size: 16px;
}
#homeIndex .footerIndex {
  padding: 15px 0;
  background: #f8f8f8;
}
#homeIndex .footerIndex p, #homeIndex .footerIndex a {
  color: #353535;
  text-transform: none;
  margin-bottom: 8px;
}
#homeIndex .btnRedeSocial {
  margin-right: 15px;
  color: rgb(119, 119, 119);
}
#homeIndex .btnRedeSocial:hover {
  color: #fff;
}
#homeIndex #divVideo {
  border-radius: 15px;
  box-shadow: 0px 25px 25px -25px #444;
  --webkit-box-shadow: 0px 25px 25px -25px #444;
  /*border: 2px solid #969696;*/
}
.divider {
  background-color: var(--colorDefault);
  color: #fff;
  padding: 40px 0px;
}
.divider p, .divider h3 {
  color: #fff !important;
  margin-bottom: 0px;
}
.divider .btnRedeSocial {
  color: #fff !important;
}
#bodyHowWorks, #bodyGalery, #bodyPlans, #bodyWarranty {
  padding: 80px 0;
  background-color: var(--backgroundDefault);
  /*margin-top: -100px;*/
}
#bodyHowWorks, #bodyWarranty {
  background-color: var(--backgroundDefault);
  color: #fff;
}
#bodyHowWorks h1, #bodyWarranty h1, #bodyWarranty .title, #bodyWarranty p {
  color: #fff;
}
#bodyHowWorks .title, #bodyKnow .title, #bodyPlans .title {
 margin-top: 15px;
 font-size: 20px;
}
#bodyHowWorks img {
  padding: 30px;
}
#bodyHowWorks .itemHowWorks {
  padding: 20px 15px;
  border-radius: 8px;
  background: white;
  color: var(--colorText);
  margin-bottom: 15px;
}
#bodyHowWorks .itemHowWorks:hover {
  box-shadow: 0px 0px 10px 1px var(--shadow);
  --webkit-box-shadow: 0px 0px 10px 1px var(--shadow);
}
#bodyHowWorks .itemHowWorks .iconItemHowWorks {
  color: var(--colorDefault);
  padding: 15px 10px;
}
#bodyKnow .itemKnow {
  box-shadow: 0px 0px 10px 1px var(--shadow);
  --webkit-box-shadow: 0px 0px 10px 1px var(--shadow);
  padding: 8px 12px;
  border-radius: 8px;
  background: white;
  margin-bottom: 15px;
}
#bodyKnow .itemKnow .title{
  font-size: 16px;
  margin-bottom: 0px;
  margin-top: 5px;
}
#bodyKnow .itemKnow span{
  font-size: 14px;
}
#bodyKnow .itemKnow i{
  color: var(--colorDefault);
}
#bodyPlans {
  color: 'initial'
}
#bodyPlans .itemPlan {
  position: relative;
  box-shadow: 0px 0px 10px 1px var(--shadow);
  --webkit-box-shadow: 0px 0px 10px 1px var(--shadow);
  padding: 25px 15px;
  border-radius: 8px;
  background: white;
  color: initial;
  margin-bottom: 15px;
  border: 2px solid #fff;
}
#bodyPlans .itemPlan .title {
  font-size: 28px;
  color: initial;
}
#bodyPlans .itemPlan .description {
  font-size: 16px;
  color: initial;
  margin-top: -15px;
}
#bodyPlans .itemPlan:hover, #bodyPlans .itemPlan.active {
  border: 2px solid var(--colorDefault);
  box-shadow: 0px 0px 20px -2px var(--colorDefault);
  --webkit-box-shadow: 0px 0px 20px -2px var(--colorDefault);
}
#bodyPlans .itemPlan .iconPlan {
  color: var(--colorDefault);
}
#bodyPlans .itemPlan li i {
  font-size: 20px;
  color: var(--colorDefault);
  margin-right: 5px;
}
#bodyPlans .itemPlan li b, #bodyPlans .itemPlan h1 {
  color: var(--colorDefault);
}
.priceMouth {
  /*padding: 0px 0px 10px 0px !important;*/
}
.priceMouth .big {
  font-size: 50px;
  /*color: var(--colorDefault);*/
}
.priceMouth .oldPrice {
  font-size: 24px;
  color: #929292;
  text-decoration: line-through;
  font-weight: bolder;
}
#bodyPlans .itemPlan .obs {
  font-size: 12px; 
  font-style: italic;
  margin: 15px, 0;
  color: initial;
}
#bodyPlans .imgDiscount {
  width: 80px;
  position: absolute;
  right: 0;
  margin-top: -20px;
  z-index: 8;
}
#bodyPlans .imgFreteGratis{
  width: "100%";
  max-width: 250px;
}
#bodyPlans .emBreve {
  position: absolute;
  left: 0;
  right: 0;
  width: 102%;
  justify-content: center;
  align-items: flex-start;
  display: flex;
  background: rgb(0 0 0 / 75%);
  height: 101%;
  flex: 1 1;
  margin: -5px;
  margin-top: -30px;
  border-radius: 5px;
  z-index: 9;
}
#bodyPlans .emBreve img{
  max-width: 250px;
  margin-top: 120px;
}
#bodyPlans .divButtonSelect {
  display: inline-flex;
  width: 100%;
  background: #fff;
  /*border: 1px solid #ced4da;*/
  border-radius: 30px;
  margin-bottom: 60px;
  box-shadow: 0px 0px 10px -1px var(--shadow);
  --webkit-box-shadow: 0px 0px 10px -1px var(--shadow);
}
#bodyPlans .divButtonSelect .itemButtonSelect {
  width: 50%;
  padding: 10px 15px;
  border-radius: 30px;
  cursor: pointer;
}
#bodyPlans .divButtonSelect .itemButtonSelect.active {
  background: var(--colorDefault);
  color: #fff;
}

#bodyPlans .image-gallery .image-gallery-slides {
  box-shadow: none;
  --webkit-box-shadow: none;
  border-radius: 10px;
  margin-bottom: 5px;
}
#bodyPlans .image-gallery .image-gallery-image {
  max-height: 350px;
}

#bodyWarranty img {
  margin: 15px;
}

.video-galery {
  width: 100%;
  min-height: 620px;
}

#footer {
  background: var(--colorDefault);
  color: #fff;
  padding: 60px 0;
}
#footer p, #footer span, #footer h1, #footer h5, #footer svg{
  color: #fff;
}

.lineDivider {
  width: 100%;
  height: 320px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: -50px;
}

.iconCheck {
  font-size: 30px;
  color: var(--colorDefault);
  margin-right: 10px;
  margin-left: 15px;
}

/* MAILCHIMP */
#formMailchimp input{
  font-family: 'Font-Regular';
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 1.5rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  margin-bottom: 15px;
}
#formMailchimp button {
  font-size: 18px;
  border: 0;
  border-radius: 5px;
  background: var(--colorDefault);
  color: #fff;
  cursor: pointer;
  padding: 12px 15px;
  margin-bottom: 15px;
}
#formMailchimp .msg-alert {
  text-align: center;
}

.divBtnWhatsApp {
  width: 60px;
  height: 60px;
  position: fixed;
  right: 0;
  bottom: 0;
  margin: 15px;
}

.btnWhatsApp {
  width: 60px;
  height: 60px;
  border-radius: 100%;
  border: 0;
  background: #4ae666;
  box-shadow: 0px 0px 8px -1px var(--shadow);
  --webkit-box-shadow: 0px 0px 8px -1px var(--shadow);
}

.btnWhatsApp:hover {
  color: #2bcc46;
}

/* Mobile Layout */
@media only screen and (max-width: 767px) {
  #homeIndex {
    height: 65vh;
  }
  #homeIndex .bodyIndex {
    padding: 20px 0;
  }
  #homeIndex .logo h1{
    font-size: 30px;
  }
  #homeIndex .bodyIndex h1 {
    font-size: 30px;
  }
  .divRedeSocial {
    text-align: center;
  }
  .itensHome {
    height: auto;
  }
  .lineDivider {
    width: 100%;
    height: 200px;
    background-image: none !important;
  }
  .divider .divRedeSocial  {
    padding-top: 15px;
  }
  .video-galery {
    width: 100%;
    min-height: 350px;
  }
  /*#homeIndex a {
    color: white;
    text-transform: uppercase;
    font-size: 10px;
  }*/
}